.image-with-text {
    position: relative;
    height: 100vh; 
    background-size: cover;
    background-position: center;
    margin-bottom: 20px; 
    /* background-attachment: fixed; */
  }

  .half-image {
    position: relative;
    height: 65vh; 
    background-size: cover;
    background-position: center;
    margin-bottom: 20px; 
    /* background-attachment: fixed; */
  }
  
  .centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8); 
    padding: 20px;
    text-align: center;
  }
  
  .centered-text p {
    margin: 0;
  }
  